import { BLOCKS } from '@contentful/rich-text-types';
import { ContentfulCollection } from 'contentful';

import { ResponsiveImageProps } from '@tb-core/components/composites/responsive-image';
import { SvgProps } from '@tb-core/components/simple/svg';
import { ImageProps } from '@tb-core/components/styled/image';
import { BynderAssetProps } from '@tb-core/types';
import { GenericContentProps, RealObject } from '@tb-core/types';
import { ContentfulDynamicEntry } from '@tb-core/types/contentful';
import { ContentfulRichTextDocument } from '@tb-core/types/contentful';

type Wordmark = (ImageProps | SvgProps) & ContentfulDynamicEntry;

export const bynderImageAdapter = (
    { thumbnails, src }: BynderAssetProps,
    output: 'hero' | 'tile'
): Pick<ResponsiveImageProps, 'src' | 'srcset'> => {
    return output === 'hero'
        ? {
              src: thumbnails?.transformBaseUrl || src,
              srcset:
                  thumbnails?.transformBaseUrl &&
                  thumbnails?.transformBaseUrl +
                      '?io=transform:fill,width:840,height:640'
          }
        : {
              src:
                  (thumbnails && thumbnails['Tile-Desktop']) ||
                  thumbnails?.transformBaseUrl ||
                  src,
              srcset: thumbnails && thumbnails['Tile-Mobile']
          };
};

export const contentfulCollectionTemplate: ContentfulCollection<GenericContentProps> = {
    items: [],
    limit: 0,
    skip: 0,
    toPlainObject: () => new Object(),
    total: 0
};

export const webpageContentDefaults = {
    __typename: 'WebpageContent',
    colorTheme: null,
    contentLink: null,
    contentfulMetadata: { tags: [] },
    daypart: null,
    description: {
        json: {
            content: [
                {
                    content: [],
                    data: {},
                    nodeType: BLOCKS.PARAGRAPH
                }
            ],
            data: {},
            nodeType: BLOCKS.DOCUMENT
        },
        links: {
            entries: {
                inline: []
            }
        }
    } as ContentfulRichTextDocument,
    entriesCollection: { items: [{}] } as ContentfulCollection<RealObject>,
    menuTiles: [],
    subtitle: null,
    wordmark: {} as Wordmark
};

interface ArticleAdapterOptions {
    slugAsCTA?: boolean;
    slugAsContentLink?: boolean;
}

export const articleToWebpageContentAdpater = (
    articles: any[],
    options?: ArticleAdapterOptions
): GenericContentProps[] => {
    const config: ArticleAdapterOptions = {
        slugAsContentLink: true,
        ...options
    };

    return articles.map(
        ({
            title,
            articleTitle,
            legal,
            publishedDate,
            featuredImage,
            slug
        }) => {
            // const description = truncateJsonDocument(content);

            return {
                ...webpageContentDefaults,
                ...(featuredImage && {
                    backgroundImage: bynderImageAdapter(
                        featuredImage[0],
                        'tile'
                    )
                }),
                ...(config.slugAsContentLink && {
                    contentLink: { url: slug, label: 'Read More' }
                }),
                ...(config.slugAsCTA && {
                    entriesCollection: {
                        ...contentfulCollectionTemplate,
                        items: [
                            {
                                __typename: 'Link',
                                label: 'Read More',
                                url: slug
                            }
                        ],
                        total: 1
                    }
                }),
                footnote: legal,
                itemTitle: title || articleTitle,
                publishedDate
                // subtitle
            };
        }
    );
};
